:root {
  --speech-responses-height: 56px;
  --speech-speaker-y-offset: 120px;
  --speech-y-gutter: 24px;
}

.speech-bubble-container {
  transform: none !important;
  bottom: 0;
  text-align: center;
  transition: bottom 400ms ease-in-out;
}

.speech-bubble-container.selected {
  transition: left 400ms ease-in-out, right 400ms ease-in-out,
    bottom 400ms ease-in-out;
}

.speech-bubble-container:not(.has-x-pos) {
  left: 0;
  right: 0px;
}

.speech-bubble-container.speaker.mindworker:not(.has-x-pos) {
  left: 50%;
}

.speech-bubble-container.has-x-pos {
  display: inline-block !important;
}

.speech-bubble-container.has-x-pos:not(.mindworker) {
  left: var(--button-pos-x);
}

.speech-bubble-container.has-x-pos.mindworker:not(.selected) {
  right: var(--speech-right, 0px);
  bottom: calc(var(--speech-pos-y, var(--speech-y-gutter)));
}

.speech-bubble-container.has-x-pos.mindworker.selected {
  right: 0px;
  bottom: calc(
    var(--speech-pos-y, var(--speech-y-gutter)) + var(--speech-speaker-y-offset) +
      var(--speech-responses-height, 0px)
  );
}

.speech-bubble-container.mindworker.speaker {
  bottom: calc(
    var(--speech-pos-y, var(--speech-y-gutter)) + var(--speech-speaker-y-offset) +
      var(--speech-responses-height, 0px)
  );
}

.speech-bubble {
  display: inline-block;
  background-color: white;
  padding: 16px 24px;
  border-radius: 24px;
  box-shadow: 0px 4px 16px rgba(17, 10, 41, 0.24),
    0px 1px 3px rgba(17, 10, 41, 0.32);
  max-width: 480px;
}

.speech-bubble-container.mindworker.speaker .speech-bubble {
  min-width: 288px;
  box-sizing: border-box;
}

.speech-bubble p {
  margin: 0;
  width: fit-content;
  color: #221452;
  font-size: 16px;
  line-height: 24px;
}

.speech-bubble-container.speaker {
  pointer-events: none !important;
}

.speech-bubble-container.speaker .speech-bubble {
  border-bottom-left-radius: 8px;
}

.speech-bubble.response {
  border-bottom-left-radius: 8px;
}

.speech-bubble.interactive {
  background-color: #6b43ef;
  color: white;
  box-shadow: 0px 4px 16px rgba(17, 10, 41, 0.16),
    0px 1px 3px rgba(17, 10, 41, 0.24);
  bottom: 0;
  transition: border-radius 400ms ease-in, background-color 400ms ease-in,
    color 400ms ease-in;
}

.speech-bubble.interactive p {
  font-size: 16px;
  line-height: 24px;
  font-family: "PoppinsBold";
  color: inherit;
}

.speech-bubble-container:not(.selected) .speech-bubble.interactive {
  cursor: pointer;
  pointer-events: all;
}

.speech-bubble-container:not(.selected) .speech-bubble.interactive:hover {
  background-color: #4427a4;
}

.speech-bubble-container.selected .interactive {
  border-bottom-right-radius: 8px;
  background-color: #ebe5ff;
  color: #221452;
}

.speech-bubble.scenario p {
  max-width: 480px;
}

.speech-bubble-container:not(.interactive):not(.mindworker):has(
    + .speech-bubble-container > .speech-bubble.interactive
  ) {
  bottom: 64px;
}

.speech-bubble-container.interactive.smooth-fade {
  transition: opacity 300ms ease-in-out;
}

.speech-bubble-container.interactive:not(.spawned) {
  opacity: 0;
}

.speech-bubble-container.interactive.spawned {
  opacity: 1;
}

.speech-bubble-container.mindworker .speech-bubble.speaker {
  text-align: left;
}

/* Mobile */
@media (max-height: 600px), (max-width: 1050px) {
  :root {
    --speech-speaker-y-offset: 56px;
    --speech-y-gutter: 0px;
  }

  .speech-bubble {
    padding: 16px;
    max-width: 540px;
  }

  .speech-bubble p {
    font-size: 14px;
    font-weight: 500;
    max-width: 540px;
  }

  .speech-bubble-container.mindworker .speech-bubble.speaker,
  .speech-bubble-container.mindworker .speech-bubble.interactive {
    min-width: 56px;
    max-width: 276px;
  }

  .speech-bubble-container.mindworker.speaker {
    max-width: 300px;
    margin-left: auto;
    text-align: left;
  }

  .speech-bubble-container.mindworker.speaker .speech-bubble {
    min-width: 0;
  }

  .speech-bubble.interactive p {
    font-size: 12px;
  }
}
