:root {
  --safe-area-border: 40px;
}

* {
  -webkit-user-select: none; /* Chrome all / Safari all */
  -moz-user-select: none; /* Firefox all */
  -ms-user-select: none; /* IE 10+ */
  user-select: none;
}

body {
  font-family: "Poppins";
  overflow: hidden;
}

#safe-area {
  padding: var(--safe-area-border);
  width: var(--canvas-width);
  height: var(--canvas-height);
  box-sizing: border-box;
  overflow: hidden;
}

#phaser {
  position: relative;
}

#phaser > div {
  width: 100% !important;
  height: 100% !important;
  transition: ease-in opacity 500ms;
  box-sizing: border-box;
  clip-path: none !important;
  overflow: visible !important;
  z-index: 2;
}

#phaser > canvas {
  margin: calc(-1 * var(--safe-area-border));
}

#phaser > div.wipe {
  opacity: 0 !important;
}

/* Mobile */
@media (max-height: 600px), (max-width: 1050px) {
  :root {
    --safe-area-border: 20px;
  }
}
