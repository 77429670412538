/* End of episode */
/* ------------ */

.next-episode-container .play-btn {
  margin-right: 16px;
}

/* End of experience */
/* ------------ */

/* Copyright */
/* ------------ */
.copyright {
  transform: none !important;
  bottom: 40px;
  right: 40px;
  position: fixed;
}

.crown-logo-container {
  transform: none !important;
  top: 0;
  right: 0;
}

/* Crown logo */
/* ------------ */
.crown-logo-return-to-survey {
  height: 72px;
}

/* Content */
/* ------------ */
.end-content {
  height: 100%;
  transform: none !important;
  display: block !important;
  align-items: center;
  align-content: center;
  margin-left: 196px;
  font-family: "Poppins";
  color: #ffffff;
}

.end-content h1 {
  font-family: "PoppinsBlack";
  font-weight: 900;
  font-size: 32px;
  line-height: 1;
  text-transform: uppercase;
  white-space: nowrap;
  margin-top: 167px;
  margin-bottom: 24px;
  text-shadow: 0px 2px 4px rgba(17, 10, 41, 0.32),
    0px 1px 3px rgba(0, 0, 0, 0.12);
}

.end-content.end-of-episode h1 {
  margin-bottom: 40px;
}

.end-content h2 {
  font-family: "PoppinsBlack";
  text-transform: uppercase;
  text-shadow: 0px 2px 4px rgba(17, 10, 41, 0.32),
    0px 1px 3px rgba(0, 0, 0, 0.12);
  margin-top: 0;
  font-size: 70px;
  margin-bottom: 56px;
  line-height: 1;
  max-width: 600px;
}

.end-content p {
  font-weight: 500;
  font-size: 20px;
  line-height: 150%;
  max-width: 420px;
  letter-spacing: -0.2px;
  margin-bottom: 60px;
  text-shadow: 0px 2px 4px rgba(17, 10, 41, 0.32),
    0px 1px 3px rgba(0, 0, 0, 0.12);
}

.end-content__button-container {
  gap: 16px;
  display: flex;
}

.end-of-experience {
  margin: 0 !important;
  width: 100%;
  text-align: center;
}

@media (min-width: 1051px) {
  .end-of-experience h1 {
    font-size: 50px;
    margin-bottom: 60px;
  }
}

/* Mobile */
@media (max-width: 1050px) {
  .crown-logo-return-to-survey {
    height: 59px;
  }

  .end-content p {
    font-size: 14px;
    max-width: 285px;
  }

  .copyright {
    bottom: 20px;
    right: 20px;
  }

  .end-content {
    margin-left: 109px;
  }

  .end-content h1 {
    margin-top: min(15.6666dvh, 70px);
    margin-bottom: 16px;
    font-size: min(3.4482vw, 28px);
  }

  .end-content h2 {
    font-size: min(6.1576vw, 50px);
    margin-bottom: max(2.9556dvh, 24px);
    max-width: 420px;
  }

  .end-content p {
    font-size: 14px;
  }

  .end-content.end-of-episode h1 {
    margin-bottom: max(2.9556dvh, 24px);
  }
}
