@import url("/assets/home-sprite.png");
@import url("/assets/home-btn-sprite.png");
@import url("/assets/home-btn-mobile.png");
@import url("/assets/play-pause.png");
@import url("/assets/contact-btn-default.png");
@import url("/assets/contact-btn-hover.png");
@import url("/assets/back-to-console.png");
@import url("/assets/episode-play.png");
@import url("/assets/take-a-break.png");
@import url("/assets/purple-play.png");
@import url("/assets/red-play.png");

@font-face {
  font-family: "PoppinsBlack";
  src: url("/assets/fonts/Poppins-Black.ttf");
  font-weight: 900;
}

@font-face {
  font-family: "Poppins";
  src: url("/assets/fonts/Poppins-Regular.ttf");
  font-weight: 400;
}

@font-face {
  font-family: "PoppinsMedium";
  src: url("/assets/fonts/Poppins-Medium.ttf");
  font-weight: 500;
}

@font-face {
  font-family: "PoppinsBold";
  src: url("/assets/fonts/Poppins-Bold.ttf");
  font-weight: 700;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeout {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.no-wrap {
  white-space: nowrap;
}

.modal-overlay {
  width: 100vw;
  height: 100vh;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.4);
  opacity: 0.3;
  margin: calc(-1 * var(--safe-area-border));
}

.menu-overlay {
  width: var(--canvas-width);
  height: var(--canvas-height);
  position: fixed !important;
  background-color: #4427a4;
  z-index: -1 !important;
  transition: 400ms cubic-bezier(0.075, 0.82, 0.165, 1) !important;
  opacity: 0;
  box-sizing: border-box;
  padding: 40px 50px;
  top: -40px;
  left: -40px;
}

.home .menu-overlay {
  width: 100dvw;
  height: 100dvh;
}

.menu-modal {
  background-color: transparent;
  width: 100%;
  height: 100%;
  font-family: "PoppinsBlack";
  display: grid !important;
  grid-template-columns: 6.05vw 28.9915vw auto;
  gap: 1.6806vw;
}

.menu-modal .left-pane {
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column;
}

.menu-modal .chapter-list {
  height: calc(var(--canvas-height) - 80px);
}

.home .menu-modal .chapter-list {
  height: calc(100dvh - 80px);
}

.home-btn-container {
  display: flex;
  gap: 20px;
  flex-direction: column;
}

/* Style the tab */
.menu-tab {
  background-color: #221452;
  height: auto;
  padding: 1.6806vw;
  border-radius: 8px;
  box-sizing: border-box;
  overflow: auto;
  display: flex;
  gap: 0.8403vw;
  flex-grow: 1;
  flex-direction: column;
  transition: none;
}

.home .menu-tab {
  height: calc(100lvh - 80px);
  flex-grow: 0;
}

.menu-tab__overlay {
  display: none;
}

/* Style the buttons that are used to open the tab content */
.menu-tab button {
  display: block;
  background-color: inherit;
  color: black;
  padding: 1.6806vw 0.8403vw;
  width: 100%;
  border: none;
  outline: none;
  text-align: left;
  cursor: pointer;
  transition: background-color 150ms ease;
  border-radius: 4px;
  box-shadow: none;
}

.menu-tab .link-md {
  display: block;
}

.menu-tab .link-xs {
  display: none;
}

/* Change background color of buttons on hover */
.menu-tab button:hover {
  background-color: #110a29;
}

/* Create an active/current "tab button" class */
.menu-tab button.active {
  background-color: #6b43ef;
}

.menu-tab button.active:hover {
  background-color: #4427a4;
}

.menu-modal span.title {
  color: #ebe5ff;
  font-style: normal;
  display: block;
  font-size: 14px;
  font-family: "PoppinsBlack";
  line-height: 16px;
  text-transform: uppercase;
}

.menu-modal span.sub-title {
  color: #ebe5ff;
  font-style: normal;
  display: block;
  font-family: "Poppins";
  font-size: 20px;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: -0.2px;
}

/* Style the tab content */
.menu-tabcontent {
  border-radius: 8px;
  padding: 1.6806vw;
  background: #221452;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}

.menu-modal .top-content {
  height: 75px;
  margin: 8px 8px 24px 8px;
  display: flex;
  gap: 20px;
}

.menu-modal .bottom-content {
  overflow-y: auto;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
}

.menu-modal .top-content .title {
  display: inline-block;
  color: #ebe5ff;
  font-family: "PoppinsBlack";
  font-size: 50px;
  font-style: normal;
  font-weight: 900;
  line-height: 150%;
  text-transform: uppercase;
}

.menu-modal .btn-v2.btn-close {
  float: right;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.bottom-content::-webkit-scrollbar,
.menu-tab::-webkit-scrollbar,
.menu-tabcontent::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.bottom-content,
.menu-tab {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.menu-tabcontent .ch-container {
  background-color: inherit;
  width: 100%;
  border: none;
  text-align: left;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 16px;
  box-shadow: none;
  border-radius: 0px;
  padding: 8px;
  transition: background-color 150ms ease;
}

.menu-tabcontent button.ch-container:hover {
  background-color: #110a29;
}

.menu-modal .thumb {
  width: 187px;
  height: 120px;
  border-radius: 4px;
  background: #d9d9d9;
}

.menu-modal .thumb img {
  width: 187px;
  height: 120px;
  border-radius: 4px;
}

.menu-tab span.title {
  margin-bottom: 2px;
}

.bottom-content span.title,
.bottom-content span.sub-title {
  margin-bottom: 4px;
}

.ch-container .status {
  display: none;
}

.ch-container.active .status {
  background-color: #4427a4;
  display: inline-block;
  padding: 6px;

  color: #fff;
  font-size: 12px;
  font-style: normal;
  font-weight: 900;
  line-height: 100%;
  /* 12px */
  text-transform: uppercase;
}

.ch-container.active span {
  color: #af97ff;
}

.menu-overlay {
  opacity: 0 !important;
}

.menu-overlay.show {
  opacity: 1 !important;
  z-index: 999 !important;
}

/* Mobile */
@media (max-height: 600px), (max-width: 1050px) {
  .menu-modal .chapter-list,
  .menu-tab {
    height: calc(var(--canvas-height) - 40px);
  }

  .home .menu-modal .chapter-list,
  .home .menu-tab {
    height: calc(100dvh - 40px);
  }

  .menu-overlay {
    padding: 20px 30px;
    top: -20px;
    left: -20px;
    width: calc(100% + 40px);
  }

  .home-btn-container {
    flex: 0 1 60px;
  }

  .menu-modal .top-content .title {
    font-size: 28px;
  }

  .menu-modal .top-content {
    height: 40px;
  }

  .menu-modal .thumb,
  .menu-modal .thumb img {
    width: 110px;
    height: 69px;
  }

  .menu-modal span.title {
    font-size: 14px;
    line-height: 16px;
  }

  .menu-modal span.sub-title {
    font-size: 14px;
    line-height: 24px;
  }

  #phaser > div.home-container .menu-overlay {
    top: -20px !important;
    left: -20px !important;
  }
}

.confirmation-modal {
  background-color: white;
  border-radius: 24px;
  text-align: center;
  width: 340px;
  padding-bottom: 40px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%) !important;
  filter: drop-shadow(0px 4px 16px rgba(17, 10, 41, 0.16)),
    drop-shadow(0px 1px 3px rgba(17, 10, 41, 0.12));
}

.confirmation-modal h2 {
  color: #221452;
  text-transform: uppercase;
  font-family: "PoppinsBlack";
  font-size: 32px;
  line-height: 44px;
  margin-bottom: 10px;
}

.confirmation-modal p {
  color: #221452;
  font-family: "Poppins";
  max-width: 276px;
  margin: 0 auto;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 32px;
}

.btn {
  padding: 16px 24px;
  color: white;
  border-radius: 28px;
  border: 0;
  font-size: 16px;
  line-height: 24px;
  font-family: "PoppinsBold";
  background-color: #6b43ef;
  /* box-shadow: 0px 12px 24px -16px rgba(68, 39, 164, 0.4),
    0px 4px 16px rgba(68, 39, 164, 0.4), 0px 1px 3px rgba(68, 39, 164, 0.4); */
  box-shadow: 0px 4px 16px rgba(17, 10, 41, 0.16),
    0px 1px 3px rgba(17, 10, 41, 0.24);
  cursor: pointer;
  outline: 0 !important;
}

.btn:hover {
  background-color: #4427a4;
}

.play-btn {
  display: inline-flex;
  outline: 0 !important;
  cursor: pointer;
  filter: drop-shadow(0px 1px 2px rgba(17, 10, 41, 0.3))
    drop-shadow(0px 4px 16px rgba(17, 10, 41, 0.13));
  text-transform: uppercase;
  color: #4427a4;
  background: #ebe5ff;
  font-size: 20px;
  padding: 24px 32px;
  border-radius: 52px;
  line-height: 1;
  font-family: "PoppinsBlack";
  align-items: center;
  gap: 6px;
  transition: background-color 150ms ease;
}

.play-btn:hover {
  background-color: #e2d9ff;
}

.take-a-break {
  border: none;
  display: inline-flex;
  outline: 0 !important;
  font-size: 20px;
  cursor: pointer;
  filter: drop-shadow(0px 1px 2px rgba(17, 10, 41, 0.3))
    drop-shadow(0px 4px 16px rgba(17, 10, 41, 0.13));
  text-transform: uppercase;
  color: #fff;
  background: #6b43ef;
  padding: 24px 32px;
  border-radius: 52px;
  line-height: 1;
  font-family: "PoppinsBlack";
  align-items: center;
  gap: 6px;
  transition: background-color 150ms ease;
}

.take-a-break:hover {
  background-color: #4427a4;
}

.confirmation-modal .btn.btn-confirm {
  margin-right: 8px;
}

.confirmation-modal .btn.btn-cancel {
  background-color: #af97ff;
}

.confirmation-modal .btn.btn-cancel:hover {
  background-color: #6b43ef;
}

.device-warning {
  background-color: #4427a4;
  height: 100%;
  width: 100%;
  z-index: 2000;
  color: #ebe5ff;
  margin: 0 auto;
  text-align: center;
  position: absolute;
  justify-content: center;
  flex-direction: column;
  display: flex !important;
  flex: 0 0 25em;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.warning-title {
  font-size: 70px;
  line-height: 60px;
  font-weight: 900;
  font-family: "PoppinsBold";
}

.warning-sub {
  font-size: 20px;
  line-height: 28px;
  font-weight: 400;
  font-family: "Poppins";
  /* padding: 0 8rem; */
  margin-top: 24px;
}

.warning-desc {
  width: 335px;
  word-wrap: break-word;
  margin: 0 auto;
  letter-spacing: -0.4;
}

.progressOverlay {
}

.progress-holder-container {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
}

.progress-holder {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  height: auto !important;
}

.progress-background {
  background-color: #6b43ef;
  border-radius: 32px;
  box-shadow: 0px 4px 16px rgba(17, 10, 41, 0.08), 0px 1px 3px #110a29;
  height: 60px;
  width: 340px;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -170px;
  margin-top: -30px;
}

.progress {
  background-color: white;
  border-radius: 24px;
  height: 40px;
  width: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -160px;
  margin-top: -20px;
}

.progress-text {
  color: white;
  text-align: center;
  width: 100%;
  text-transform: uppercase;
  position: absolute;
  font-size: 32px;
  font-family: "PoppinsBlack";
  top: 50%;
  margin-top: 50px;
}

.card-collection-wrapper {
  display: grid;
  grid-template-columns: auto auto auto auto;
  width: 100%;
  background: transparent;
  z-index: 1;
  position: relative;
  cursor: pointer;
  gap: 1.2315vw;
}

@media (max-width: 1050px) {
  .take-a-break,
  .play-btn {
    padding: min(2.2167vw, 18px) min(1.97vw, 16px);
    font-size: 12px;
    gap: 0;
  }
}

@media (max-width: 1200px) {
  .card-collection-wrapper {
    grid-template-columns: auto auto auto;
  }
}

@media (max-width: 799px) {
  .card-collection-wrapper {
    grid-template-columns: auto auto;
  }
}

.card-wrapper {
  position: relative;
  padding-bottom: 25lvh;
}

.card-shadow {
  position: absolute;
  content: "";
  height: 100%;
  width: 40px;
  background: linear-gradient(270deg, #4427a4 -7.21%, rgba(68, 39, 164, 0) 50%);
  bottom: 0;
  z-index: 2;
}

.swipe-div {
  position: absolute;
  content: "";
  height: 96px;
  width: 72px;
  top: 50%;
  margin-top: -48px;
  z-index: 3;
}

@media (max-width: 1200px) {
  .swipe-div,
  .card-shadow {
    margin-left: 10vw;
  }

  .cards-container-mtp .swipe-div,
  .cards-container-mtp .card-shadow {
    margin-left: 0;
  }
}

.swipe-div img {
  height: 96px;
  filter: drop-shadow(0px 1px 2px rgba(17, 10, 41, 0.3))
    drop-shadow(0px 4px 16px rgba(17, 10, 41, 0.13));
}

.card-shadow.hidden {
  display: none;
}

.card {
  overflow-x: auto;
  border-radius: 24px;
  flex-direction: column;
  filter: drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.12))
    drop-shadow(0px 2px 4px rgba(17, 10, 41, 0.32));
  transition: all 0.2s ease-in-out;
  position: relative;
}

/* @media (max-height: 600px), (max-width: 1050px) {
  .card {
    width: calc(296px / 1.5);
    height: calc(376px / 1.5 + 16px);
    margin-right: 16px;
    display: flex !important;
    flex: 1 0 calc(296px / 1.5);
  }
} */

.card.mtp .card-description {
  padding: 24px;
  border-radius: 0 0 20px 20px;
  height: 88px;
}

.card.mtp .card-title {
  margin-top: 0;
  margin-bottom: 5px;
}

.card:hover {
  transform: scale(1.05);
  border-radius: 24px;
  filter: drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.12))
    drop-shadow(0px 2px 4px rgba(17, 10, 41, 0.32));
  cursor: pointer;
}

.card.pressed,
.card.pressed:hover {
  transform: scale(1);
}

.card-image {
  position: relative;
  height: 23.5418vw;
  text-align: center;
}

.card-image.mtp {
  height: 240px;
}

.card-inactive {
  pointer-events: none;
}

.card-collection-wrapper.medium .card {
  width: 283px;
  height: 360px;
  flex: 1 0 283px;
}

.card-collection-wrapper.medium .card-image {
  height: 287px;
}

.image {
  height: inherit;
  position: relative;
  width: 100%;
  max-width: 100%;
  object-fit: cover;
}

.card-description {
  background: #ffffff;
  display: flex !important;
  flex-direction: column;
  padding: 25px 24px;
  position: relative;
}

.card-title {
  font-family: "PoppinsBlack";
  font-size: 14px;
  line-height: 16px;
  text-transform: uppercase;
}

@media (max-height: 600px), (max-width: 1050px) {
  .card-description {
    padding: 16px;
  }
  .card-title {
    margin-top: 16px;
  }
}

.card-desc {
  font-family: "PoppinsMedium";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  letter-spacing: -0.4px;
}

.card-desc br {
  content: " ";
}

.card-desc br::after {
  display: inline;
  content: " ";
}

@media (max-width: 1500px) and (min-width: 801px),
  (max-width: 650px) and (min-width: 600px) {
  .card-desc br {
    display: block;
  }
}

@media (max-height: 600px), (max-width: 1050px) {
  .card-desc {
    font-size: calc(20px / 1.4);
    line-height: calc(28px / 1.4);
    letter-spacing: calc(-0.4px / 1.4);
  }

  .card-image {
    height: 30.5418vw;
  }
}

.card-mtp-desc {
  font-family: "Poppins";
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.2px;
}

.card-btn {
  min-width: 44px;
  min-height: 44px;
  max-width: 44px;
  max-height: 44px;
  border-radius: 50%;
  position: absolute;
  text-align: center;
  right: 24px;
  filter: drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.12))
    drop-shadow(0px 2px 4px rgba(17, 10, 41, 0.32));
}

.card-btn:not(.card-lock) {
  background: url("/assets/purple-play.png") no-repeat;
  background-size: 200%;
}

.card:hover .card-btn:not(.card-lock) {
  background-position: -44px;
}

.card-btn.red:not(.card-lock) {
  background: url("/assets/red-play.png") no-repeat;
  background-size: 200%;
}

.card-btn img {
  margin-top: 13px;
}

@media (max-height: 600px), (max-width: 1050px) {
  .card-btn {
    min-width: 33px;
    min-height: 33px;
    max-width: 33px;
    max-height: 33px;
    right: 16px;
    margin-top: 20px;
  }

  .card:hover .card-btn:not(.card-lock) {
    background-position: -33px;
  }
}

@media (max-width: 599px) {
  .card-collection-wrapper {
    gap: max(20px, 2.463vw);
    grid-template-columns: auto;
  }

  .card-image {
    width: 100%;
    height: auto;
    max-width: 100%;
  }

  .card-image img {
    aspect-ratio: 1.68 / 1;
    height: auto;
  }

  .card-title,
  .card-btn {
    margin-top: 0;
  }
}

.default {
  color: #4427a4;
}

.red {
  color: #a8321d;
}

.blue {
  color: #50a5db;
}

.fear {
  color: #b89bdd;
}

.yellow {
  color: #e4b40a;
}

.green {
  color: #82b552;
}

.overlay {
  position: fixed;
  /* Sit on top of the page content */
  display: none;
  /* Hidden by default */
  width: 100vw;
  /* Full width (cover the whole page) */
  height: 100vh;
  /* Full height (cover the whole page) */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin-top: calc(-1 * var(--safe-area-border) - var(--safe-area-margin-top));
  margin-left: calc(
    -1 * var(--safe-area-border) - var(--safe-area-margin-left)
  );
  margin-right: calc(-1 * var(--safe-area-border));
  margin-bottom: calc(-1 * var(--safe-area-border));
  /* background-color: lightcoral; */
  background-color: #4427a4;
  /* opacity: 0 !important; */
  z-index: 2;
  /* Specify a stack order in case you're using a different order for other elements */
  /* top: -30px;
  left: -64px;
  right: 0;
  bottom: -104px; */
  pointer-events: none;
}

.logo-group-container {
  position: sticky !important;
  top: 40px;
  left: 0;
  width: 100%;
  z-index: 2;
  display: flex;
  justify-content: flex-end;
}

.logo-group-container::before {
  display: none;
  background: linear-gradient(180deg, #110a29 36.74%, rgba(17, 10, 41, 0) 100%);
  opacity: 0.8;
  height: 20vh;
  width: calc(100vw + 80px);
  position: absolute;
  top: -40px;
  left: -40px;
  content: "";
  z-index: 2;
}

.logo-group-container > div {
  transition: width 700ms cubic-bezier(0.075, 0.82, 0.165, 1);
  width: 21.798vw;
}

@media (max-width: 1050px) {
  .logo-group-container {
    left: 20px;
    top: 20px;
  }

  .logo-group-container::before {
    width: calc(100vw + 40px);
    top: -20px;
    left: -20px;
  }
}

@media (max-height: 600px), (max-width: 1050px) {
  .logo-group-container.fade-out {
    opacity: 0 !important;
  }

  .logo-group-container.fade-in {
    opacity: 0 !important;
  }
}

.homeLogo {
  max-width: 100%;
  position: relative;
  z-index: 3;
}

.homeLogo.medium {
  width: 256px;
  margin: 0 15px;
}

.inspired-logo {
  width: 234px;
}

.cards-container-container {
  z-index: 10000 !important;
}

.cards-container {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
}

@media (max-height: 600px), (max-width: 1050px) {
  .cards-container {
    justify-content: flex-start;
    top: 20vh;
  }
}

.home-title-mtp {
  height: 100vh;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  margin-left: 80px;
}

@media (max-width: 1200px) {
  .home-title-mtp {
    margin-left: 40px;
  }
}

.home-title-mtp h2 {
  text-shadow: 0px 2px 4px 0px rgba(17, 10, 41, 0.32);
}

.home-title-mtp p {
  text-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}

.home-title {
  max-width: 39.7656vw;
  transition: all 700ms cubic-bezier(0.075, 0.82, 0.165, 1);
  max-height: 76.6666lvh;
  overflow: hidden;
}

.home-title.toggled {
  opacity: 0;
  max-height: 0;
}

.home-title h2 {
  font-family: "PoppinsBlack";
  color: #ffffff;
  font-size: max(31px, 3.8177vw);
  line-height: 100%;
  filter: drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.12))
    drop-shadow(0px 2px 4px rgba(17, 10, 41, 0.32));
  margin-top: 0;
  margin-bottom: 20px;
  text-transform: uppercase;
}

.home-title p {
  font-family: "Poppins";
  color: #ffffff;
  font-size: 20px;
  line-height: 140%;
  letter-spacing: -0.2px;
  filter: drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.12))
    drop-shadow(0px 2px 4px rgba(17, 10, 41, 0.32));
  margin-bottom: 43px;
}

.copyright {
  font-family: "Poppins";
  font-size: 12px;
  line-height: 24px;
  letter-spacing: -0.2px;
  color: #ffffff;
  font-weight: 700;
  z-index: 10;
}

@media (max-width: 599px) {
  .home-title {
    width: 100%;
    max-width: 100%;
  }

  .home-title p {
    font-size: 14px;
    margin-bottom: 20px;
  }

  .crown-logo {
    width: 140px;
  }
}

#footer-logo-group {
  position: absolute;
  width: 100%;
  left: 0;
  bottom: -10px;
  display: grid !important;
  grid-auto-flow: column;
  justify-content: space-between;
  align-items: end;
  padding: 40px 40px 0 40px;
  box-sizing: border-box;
  pointer-events: none;
}

.lock-svg {
  width: 16px;
  height: 18px;
  margin-top: 13px;
}

.card-lock {
  right: 15px;
  position: absolute;
  top: 0px;
}

.title-background {
  height: 100%;
  width: 100%;
  position: relative;
  pointer-events: none;
}

.home-mind-worker {
  position: relative;
  height: 100vh;
  max-height: 100%;
  display: flex;
  align-items: center;
  left: 20%;
}

@media (max-height: 600px), (max-width: 1050px) {
  #footer-logo-group {
    grid-auto-flow: row;
    gap: 20px;
  }

  .home-mind-worker {
    display: none;
  }

  .lock-svg {
    margin-top: 7px;
  }
}

@media (max-width: 1200px) {
  .home-mind-worker {
    left: 15%;
  }
}

.home-mind-worker img {
  height: 50vh;
}

.contact-btn {
  width: 223px;
  height: 72px;
  background-image: url("/assets/contact-btn-default.png"),
    url("/assets/contact-btn-hover.png");
  display: inline-block;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 223px 72px, 0;
  filter: drop-shadow(0px 4px 16px rgba(17, 10, 41, 0.16))
    drop-shadow(0px 1px 3px rgba(17, 10, 41, 0.24));
}

.contact-btn:hover,
.contact-btn.selected {
  background-size: 0, 223px 72px;
}

.crown-logo-mtp {
  height: 72px;
  margin-right: 40px;
}

.mtp-logo-container {
  width: 100vw;
  position: relative;
  z-index: 10;
}

.mtp-logo-holder {
  position: absolute;
  top: 40px;
  right: 40px;
}

.tabs {
}

.tabs .tab-titles {
  display: flex;
  gap: 16px;
  flex-direction: row;
  margin-bottom: 40px;
  position: relative;
}

.tabs .tab-titles::after {
  content: " ";
  display: block;
  position: absolute;
  height: 2px;
  width: 100%;
  bottom: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.6);
}

.tabs .tab-title {
  display: inline-block;
  font-size: 16px;
  font-style: normal;
  font-family: "PoppinsBlack";
  line-height: 100%;
  text-transform: uppercase;
  color: rgba(255, 255, 255, 0.6);
  cursor: pointer;
  padding: 16px 8px;
  border-bottom: 2px solid transparent;
  transition: color 0.3s ease-in-out;
}

.tabs .tab-title.active {
  color: rgba(255, 255, 255, 1);
  border-bottom-color: rgba(255, 255, 255, 1);
}

.tabs .tab-contents {
  position: relative;
}

.tabs .tab-content {
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  top: 0;
  left: 0;
  z-index: -1;
  pointer-events: none;
  display: none;
}

.tabs .tab-content.active {
  opacity: 1;
  z-index: 1;
  pointer-events: all;
  display: block;
}

.content-container {
  width: 100%;
  margin-top: 160px;
  transition: all 700ms cubic-bezier(0.075, 0.82, 0.165, 1);
  position: relative;
}

.home-background {
  width: 100%;
  height: 100lvh;
  top: 0;
  left: 0;
  position: fixed;
  z-index: -1;
}

.home-background-image {
  background: url("/assets/home-background-v2.jpg") right bottom;
  background-size: cover;
  height: 100lvh;
  width: 75%;
  margin-left: 25%;
}

.home-background-overlay1 {
  background: linear-gradient(
    83deg,
    #221452 57.74%,
    rgba(34, 20, 82, 0) 82.35%
  );
  width: 65%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.home-background-overlay2 {
  background: #221452;
  opacity: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  transition: opacity 700ms cubic-bezier(0.075, 0.82, 0.165, 1);
}

.home-background-overlay2.toggled {
  opacity: 0.9;
}

.home-top-overlay {
  background: linear-gradient(180deg, #110a29 36.74%, rgba(17, 10, 41, 0) 100%);
  opacity: 0.8;
  height: 20vh;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
}

#phaser > div.home-container {
  position: relative !important;
}

#phaser > div.home-container .mute-btn-div {
  display: none !important;
}

#phaser > div.home-container + canvas {
  display: none;
}

#phaser > div.home-container .menu-modal {
  top: 0;
}

.enable-scroll {
  overflow-y: scroll !important;
  -ms-overflow-style: none;
  /* scrollbar-width: none; */
  -webkit-overflow-scrolling: auto !important;
}

.home #safe-area {
  overflow: visible;
}

.enable-scroll::-webkit-scrollbar {
  width: 0 !important;
}

.home-area {
  margin-top: 0 !important;
  margin-left: 0 !important;
  width: 100% !important;
}

.hide {
  display: none !important;
}

.for-parents {
  display: grid;
  gap: 9.1133vw;
  grid-template-columns: 41.875vw auto;
  color: #fff;
  padding-bottom: 25lvh;
}

.for-parents-content {
  display: flex;
  gap: 1.5625vw;
  flex-direction: column;
}

.for-parents-content h3 {
  margin: 0;
  font-size: 34px;
  font-family: "PoppinsBlack";
  text-transform: uppercase;
}

.for-parents-content p {
  font-size: 20px;
  margin: 0;
  line-height: 140%;
}

.helpful-resources-links {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  gap: 1.25vw;
  flex-direction: column;
}

.helpful-resources-links a {
  color: #fff;
  font-family: "PoppinsBlack";
  font-size: 20px;
}

.helpful-resources-logo {
  width: max(140px, 16.9531vw);
  margin-top: 1.5625vw;
}

.helpful-resources-logo img {
  max-width: 100%;
}

#svgShape {
  z-index: 0 !important;
  display: block;
  position: absolute;
  pointer-events: auto;
  mix-blend-mode: normal;
  top: 1;
}

#svgShape svg#shape,
#svgShape svg#blured-shape1,
#svgShape svg#blured-shape2 {
  position: absolute;
  top: 25%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: calc(var(--canvas-width) - 57%);
}

#backgroundColorDisplay.bgcDisplay {
  position: absolute;
  z-index: 0;
  top: calc(var(--safe-area-border) * -1);
  left: calc(var(--safe-area-border) * -1);
  width: var(--canvas-width) !important;
  height: var(--canvas-height) !important;
}

.console-finish-button {
  transform: none !important;

  right: 0;
  z-index: 999 !important;
}

body.loading {
  background: linear-gradient(180deg, #3b2489 0%, #221452 100%);
}

.splash-screen {
  position: relative;
  overflow: hidden !important;
}

.splash-screen__overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100lvh;
  background: #221452;
  opacity: 1;
  display: block;
  z-index: 998;
  animation: overlayOpacity 3s ease-out forwards;
  animation-delay: 1s;
}

.splash-screen__logo {
  width: max(161px, 21.5625vw);
  position: absolute;
  top: 0;
  transform: translate(50%, -100%);
  right: 50%;
  z-index: 999;
  animation: fallAndBounce 3s ease-in-out forwards;
  animation-delay: 1s;
  transition: width 350ms cubic-bezier(0.075, 0.82, 0.165, 1);
}

.splash-screen__logo.hide {
  display: none;
}

.splash-screen__logo.toggled {
  width: 16.798vw;
}

.splash-screen__logo.splash-screen__logo--fixed {
  z-index: 2 !important;
  top: 40px;
  right: 40px;
  animation: none;
  transform: translate(0, 0);
}

.end-of-scene-bg {
  position: fixed !important;
  width: calc(100% + 80px);
  height: calc(100% + 80px);
  top: -40px;
  left: -40px;
}

.end-of-scene-bg::before {
  content: " ";
  display: block;
  width: 100%;
  height: 100%;
  background: linear-gradient(180deg, #221452 0%, rgba(34, 20, 82, 0) 100%);
  position: absolute;
}

.end-of-scene-image {
  max-width: 100%;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center bottom;
}

.end-of-scene-bg {
  position: fixed !important;
  width: calc(100% + 80px);
  height: calc(100% + 80px);
  top: -40px;
  left: -40px;
}

.end-of-scene-bg::before {
  content: " ";
  display: block;
  width: 100%;
  height: 100%;
  background: linear-gradient(180deg, #221452 0%, rgba(34, 20, 82, 0) 100%);
  position: absolute;
}

.end-of-scene-image {
  max-width: 100%;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center bottom;
}

.end-screen-overlay {
  position: fixed;
  width: calc(100% + 80px);
  height: calc(100% + 80px);
  background: linear-gradient(180deg, #221452 0%, rgba(34, 20, 82, 0) 100%);
  top: -40px;
  left: -40px;
}

.crown-logo {
  width: min(16.9531vw, 217px);
  transition: none;
  transform: none !important;
  bottom: 40px;
  position: fixed;
}

.crown-logo img {
  max-width: 100%;
  width: 100%;
  height: auto;
}

@media (max-height: 600px), (max-width: 1050px) {
  .content-container {
    margin-top: 88px;
  }

  .splash-screen__overlay {
    animation-delay: 0s;
  }

  .splash-screen__logo {
    animation: fallAndBounceMobile 3s ease-in-out forwards;
  }

  .splash-screen__logo.splash-screen__logo--fixed {
    top: 20px;
    right: 20px;
  }

  .end-of-scene-bg {
    width: calc(100% + 40px);
    height: calc(100% + 40px);
    top: -20px;
    left: -20px;
  }

  .end-screen-overlay {
    width: calc(100% + 40px);
    height: calc(100% + 40px);
    top: -20px;
    left: -20px;
  }

  .crown-logo {
    bottom: 20px;
    width: 140px;
  }
}

@media (max-width: 599px) {
  .content-container {
    margin-top: var(--bg-height);
  }

  .content-container.toggled {
    margin-top: 92px;
  }

  .home-background-image,
  .home-background-overlay1 {
    width: 100%;
  }

  .home-background-image {
    margin-left: 0;
    aspect-ratio: 125 / 137;
    height: auto;
    background-position: 87% bottom;
    position: static;
  }

  .home-background-overlay1 {
    background: linear-gradient(
      0deg,
      #221452 52.21%,
      rgba(34, 20, 82, 0) 62.43%
    );
    top: auto;
    bottom: 0;
    height: min(100%, 900px);
  }

  .tabs .tab-titles {
    margin-bottom: 20px;
  }

  .tabs .tab-title {
    padding: 8px 4px;
  }

  .tabs .tab-title:first-child {
    padding-left: 0;
  }

  .menu-modal {
    display: flex !important;
    flex-direction: column;
    gap: 10px;
  }

  .menu-modal .menu-tab {
    height: auto;
  }

  .menu-modal .left-pane {
    height: auto;
    position: relative;
    background: rgba(17, 10, 41, 0.5);
    padding: 1.6806vw;
    border-radius: 8px;
    box-sizing: border-box;
  }

  .menu-modal .chapter-list {
    height: auto;
  }

  .menu-modal .chapter-list {
    flex-grow: 1;
    overflow: hidden;
  }

  .menu-modal .home-btn-container {
    flex-direction: row;
    gap: 10px;
    flex-basis: 48px;
  }

  .menu-modal .home-btn-container button {
    padding: 11px !important;
    width: auto !important;
    height: auto !important;
  }

  .home .menu-modal .chapter-list,
  .home .menu-tab {
    max-height: calc(100dvh - var(--menu-height));
  }

  .menu-modal .home-btn-container svg {
    width: 24px !important;
    height: 24px !important;
  }

  .menu-modal .home-btn-container .btn-home {
    padding: 7px !important;
  }

  .menu-modal .home-btn-container .btn-home svg {
    width: 32px !important;
    height: 32px !important;
  }

  .menu-modal .top-content {
    margin-bottom: 10px;
  }

  .menu-modal .top-content .title {
    font-size: 20px;
  }

  .menu-tab .link-md {
    display: none;
  }

  .menu-tab .link-xs {
    display: block;
  }

  .menu-tab {
    overflow-y: auto;
    overscroll-behavior-x: contain;
    scroll-snap-type: x mandatory;
    max-width: 500px;
    display: grid;
    grid-auto-flow: column;
    gap: 4px;
    padding: 0;
    border-radius: 0;
  }

  .menu-tab__overlay {
    display: block;
    width: 25px;
    height: 100%;
    top: 0;
    right: 0;
    background: linear-gradient(
      270deg,
      #18084f 4.69%,
      rgba(9, 0, 38, 0) 76.56%
    );
    position: absolute;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
  }

  .menu-tab button {
    width: max(84px, 22.5vw);
    aspect-ratio: 1.4 / 1;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    scroll-snap-align: center;
    background: rgba(17, 10, 41, 0.5);
  }

  .menu-tab button:last-child {
    margin-right: 0;
  }

  .menu-tabcontent {
    overflow-y: auto;
  }

  .crown-logo {
    width: 140px;
  }

  .for-parents {
    grid-template-columns: auto;
    gap: 24px;
  }

  .for-parents-content {
    gap: 10px;
  }

  .for-parents-content h3 {
    font-size: 20px;
  }

  .for-parents-content p {
    font-size: 14px;
  }

  .for-parents-content.helpful-resources {
    gap: 20px;
  }

  .for-parents-content.helpful-resources > p {
    font-size: 16px;
  }

  .for-parents-content .helpful-resources-links {
    gap: 16px;
    font-size: 16px;
  }
}

@media (max-width: 375px) {
  .home-background-overlay1 {
    background: linear-gradient(
      0deg,
      #221452 40.21%,
      rgba(34, 20, 82, 0) 62.43%
    );
  }
}

@media (max-width: 360px) {
  .home-background-overlay1 {
    background: linear-gradient(
      0deg,
      #221452 46.21%,
      rgba(34, 20, 82, 0) 62.43%
    );
  }
}

@keyframes overlayOpacity {
  0% {
    opacity: 1;
    display: block;
    z-index: 999;
  }
  85% {
    opacity: 1;
    display: block;
    z-index: 999;
  }
  100% {
    opacity: 0;
    display: none !important;
    z-index: -1;
  }
}

@keyframes fallAndBounce {
  0% {
    top: 0;
    right: 50%;
    transform: translate(50%, -100%);
    z-index: 999;
  }
  5% {
    top: 65%;
    right: 50%;
    transform: translate(50%, -50%);
    z-index: 999;
  }
  9% {
    top: 48%;
    right: 50%;
    transform: translate(50%, -50%);
    z-index: 999;
  }
  12% {
    top: 52%;
    right: 50%;
    transform: translate(50%, -50%);
    z-index: 999;
  }
  15% {
    top: 49%;
    right: 50%;
    transform: translate(50%, -50%);
    z-index: 999;
  }
  18% {
    top: 50%;
    right: 50%;
    transform: translate(50%, -50%);
    z-index: 999;
  }
  80% {
    top: 50%;
    right: 50%;
    transform: translate(50%, -50%);
    z-index: 999;
  }
  99% {
    z-index: 999;
  }
  100% {
    top: 40px;
    right: 40px;
    z-index: auto;
    transform: translate(0, 0);
  }
}

@keyframes fallAndBounceMobile {
  0% {
    top: 0;
    right: 50%;
    transform: translate(50%, -100%);
    z-index: 999;
  }
  5% {
    top: 65%;
    right: 50%;
    transform: translate(50%, -50%);
    z-index: 999;
  }
  9% {
    top: 48%;
    right: 50%;
    transform: translate(50%, -50%);
    z-index: 999;
  }
  12% {
    top: 52%;
    right: 50%;
    transform: translate(50%, -50%);
    z-index: 999;
  }
  15% {
    top: 49%;
    right: 50%;
    transform: translate(50%, -50%);
    z-index: 999;
  }
  18% {
    top: 50%;
    right: 50%;
    transform: translate(50%, -50%);
    z-index: 999;
  }
  80% {
    top: 50%;
    right: 50%;
    transform: translate(50%, -50%);
    z-index: 999;
  }
  99% {
    z-index: 999;
  }
  100% {
    top: 20px;
    right: 20px;
    z-index: auto;
    transform: translate(0, 0);
  }
}
