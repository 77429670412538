/* v2 */
.btn-v2 {
  color: var(--purple-purple-v, #4427a4);
  justify-content: center;
  align-items: center;
  display: inline-flex;

  /* Button/Button/Large */
  font-family: "PoppinsBlack";
  font-size: 20px;
  font-style: normal;
  font-weight: 900;
  line-height: 100%; /* 20px */
  text-transform: uppercase;
  cursor: pointer;
  padding: 24px 32px;
  border: 0;
  border-radius: 36px;
  background: var(--purple-purple-5, #ebe5ff);

  transition: 150ms ease;

  /* Button Shadow */
  box-shadow: 0px 1px 3px 0px rgba(17, 10, 41, 0.24),
    0px 4px 16px 0px rgba(17, 10, 41, 0.16);
}

.btn-v2:hover {
  background: var(--purple-purple-40, #e2d9ff);
}

.btn-v2.ghost {
  background: rgba(17, 10, 41, 0.3);
}

.btn-icon {
  display: inline-flex;
}

.btn-v2.btn-share,
.btn-v2.btn-home {
  height: 6.05vw;
}

.btn-v2.btn-share {
  background: rgba(17, 10, 41, 0.5);
  transition: 150ms ease;
  color: #fff;
  max-height: 75px;
}

.btn-v2.btn-share:hover {
  background: rgba(17, 10, 41, 0.75);
}

.btn-v2.btn-close {
  width: 6.05vw;
  height: 6.05vw;
  padding: 0px;
  background: rgba(17, 10, 41, 0.5);
  box-shadow: none;
  transition: 150ms ease;
  border-radius: 999px;
}

#close-icon {
  color: white;
  width: 2.689vw;
  height: 2.689vw;
}

.btn-v2.ghost.btn-close:hover {
  background: rgba(17, 10, 41, 0.75);
}

/* Home */
/* ------------ */
.home-btn {
  display: inline-block;
  width: 6.05vw;
  height: 6.05vw;
  background: url("/assets/home-btn-sprite.png") no-repeat;
  cursor: pointer;
  background-size: 200%;
  filter: drop-shadow(0px 1px 2px rgba(17, 10, 41, 0.3))
    drop-shadow(0px 4px 16px rgba(17, 10, 41, 0.13));
}

.home-btn:hover,
.home-btn.selected {
  background-position: -180px 0;
}

/* home v2 */
.btn-v2.btn-home.ghost {
  color: white;
  height: 6.05vw;
  width: 6.05vw;
  padding: 0;
  background: rgba(17, 10, 41, 0.5);
  box-shadow: none;
  transition: 150ms ease;
  border-radius: 999px;
}

.btn-v2.btn-home.ghost:hover {
  background: rgba(17, 10, 41, 0.75);
}

#home-icon {
  color: white;
  width: 2.689vw;
  height: 2.689vw;
}

.btn-v2.ghost.btn-home:hover #home-icon {
  color: #6b43ef;
}

@media (max-height: 600px), (max-width: 1050px) {
  .home-btn {
    width: 48px;
    height: 48px;
    background: url("/assets/home-btn-mobile.png") no-repeat;
    background-size: cover;
  }

  .home-btn:hover,
  .home-btn.selected {
    background-position: center;
  }
}

/* Back to console */
/* ------------ */

.console-close-button {
  transform: none !important;
  right: 0;
  width: 305px;
  height: 72px;
  display: inline-block;
  background: url("/assets/back-to-console.png") no-repeat;
  background-size: 200%;
  filter: drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.12))
    drop-shadow(0px 2px 4px rgba(17, 10, 41, 0.32));
  cursor: pointer;
}

.console-close-button a {
  display: block;
  width: 100%;
  height: 100%;
}

.console-close-button a:hover {
  background-position: -305px;
}

@media (max-height: 600px), (max-width: 1050px) {
  .console-close-button {
    width: 186px;
    height: 48px;
  }

  .console-close-button a {
    background-size: 372px;
  }
}

/* Play/pause button v2 */
/* ------------ */
.play-btn-div {
  transform: none !important;
  right: 0;
  bottom: 0;
  transition: none !important;
  width: 100% !important;
  height: 100% !important;
}

.play-btn-div .play-pause-btn {
  position: absolute;
  bottom: 0;
  right: 162px;
  transition: background-color 150ms ease !important;
}

.play-btn-div .btn-skip {
  bottom: 0;
  right: 0;
  position: absolute;
}

.play-btn-div.video-paused .play-pause-btn {
  width: 10.084vw !important;
  height: 10.084vw !important;
  top: calc(50% - 5.042vw);
  right: auto;
  bottom: auto;
  left: calc(50% - 5.042vw);
  background: rgba(68, 39, 164, 0.8);
}

.play-btn-div.video-paused .play-pause-btn:hover {
  background: rgba(68, 39, 164, 1);
}

.btn-v2.play-pause-btn {
  width: 72px;
  height: 72px;
  padding: 0;
  border-radius: 999px;
}

#play-icon,
#pause-icon,
#mute-icon,
#muted-icon {
  color: #af97ff;
  width: 32px;
  height: 32px;
}

#play-icon {
  width: 4.0336vw;
  height: 4.0336vw;
}

.btn-v2.play-pause-btn #play-icon {
  display: inline-flex;
}

.btn-v2.play-pause-btn #pause-icon {
  display: none;
}

.btn-v2.play-pause-btn.pause #play-icon {
  display: none;
}

.btn-v2.play-pause-btn.pause #pause-icon {
  display: inline-flex;
}
/* mute btn */
.mute-btn-div {
  transform: none !important;
  left: 0;
  bottom: 0;
}

.btn-v2.btn-v2.btn-menu {
  background: rgba(17, 10, 41, 0.5);
  color: #fff;
  box-shadow: none !important;
  transition: 150ms ease;
  border-radius: 100px;
}

.btn-v2.btn-v2.btn-menu:hover {
  background: rgba(17, 10, 41, 0.75);
}

.btn-v2.mute-btn {
  width: 72px;
  height: 72px;
  padding: unset;
}

.btn-v2.mute-btn #mute-icon {
  display: inline-flex;
}

.btn-v2.mute-btn #muted-icon {
  display: none;
}

.btn-v2.mute-btn.muted #mute-icon {
  display: none;
}

.btn-v2.mute-btn.muted #muted-icon {
  display: inline-flex;
}

/* skip button */
.skip-btn {
  width: 55px;
  height: 72px;
  border: 0;
  background-color: transparent;
  cursor: pointer;
  background-image: url("/assets/play-pause.png");
  background-position: 59.4%;
  background-repeat: no-repeat;
  background-size: 638px 72px;
  filter: drop-shadow(0px 1px 2px rgba(17, 10, 41, 0.3))
    drop-shadow(0px 4px 16px rgba(17, 10, 41, 0.13));
  border-radius: 25px;
  margin-left: 5px;
}

.skip-btn:focus {
  outline: 0 !important;
}

.skip-btn:hover {
  background-position: 85.5%;
}

/* v2 skip btn */
#skip-icon {
  color: #af97ff;
  width: 32px;
  height: 32px;
}

.btn-v2.btn-skip {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 72px;
  gap: 6px;
  flex-shrink: 0;
  margin-left: 12px;
}

/* Mobile */
@media (max-height: 600px), (max-width: 1050px) {
  .btn-v2.play-pause-btn,
  .btn-v2.mute-btn {
    width: 48px;
    height: 48px;
  }

  .btn-v2.play-pause-btn {
    right: 102px;
  }

  .btn-v2.btn-menu,
  .btn-v2.btn-finished,
  .btn-v2.btn-skip {
    height: 48px;
    padding: 17px 16px;
    font-size: 12px;
  }

  .btn-v2.btn-share {
    padding: 17px 10px;
    font-size: 12px;
    max-height: 40px;
  }

  #close-icon,
  #share-icon,
  #skip-icon,
  #share-icon,
  #pause-icon,
  #mute-icon,
  #muted-icon {
    width: 24px;
    height: 24px;
  }
}
