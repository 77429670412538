@keyframes modalFadeIn {
  0% {
    opacity: 0;
    display: none;
  }
  100% {
    opacity: 1;
    display: block;
  }
}

@keyframes modalFadeout {
  0% {
    opacity: 1;
    display: block;
  }
  100% {
    opacity: 0;
    display: none;
  }
}

@keyframes slide-right-in {
  0% {
    opacity: 0;
    display: none;
    transform: translateX(100%);
  }
  100% {
    opacity: 1;
    display: block;
    transform: translateX(0);
  }
}

@keyframes slide-right-out {
  0% {
    opacity: 1;
    display: block;
    transform: translateX(0);
  }
  100% {
    opacity: 0;
    display: none;
    transform: translateX(100%);
  }
}

.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  z-index: 1001;
  padding: 2.34375vw;
  box-shadow: 0 1px 3px 0 rgba(17, 10, 41, 0.12),
    0 4px 16px 0 rgba(17, 10, 41, 0.16);
  border-radius: 1.875vw;
  box-sizing: border-box;
  display: none;
  animation: modalFadeIn 0.4s forwards;
}

.modal__overlay {
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  width: 100vw;
  height: 100lvh;
  display: none;
  animation: modalFadeIn 0.4s forwards;
}

.modal.show,
.modal__overlay.show {
  display: block;
}

.modal__header {
  position: relative;
  min-height: 3.75vw;
  margin-bottom: 1.0937vw;
}

.modal__title {
  font-family: "PoppinsBlack";
  line-height: 1;
  font-size: 2.1875vw;
  color: #221452;
  text-transform: uppercase;
}

.modal__close {
  position: absolute;
  padding: 1.25vw;
  border-radius: 999px;
  background-color: #ebe5ff;
  border: none;
  right: 0;
  top: 0;
  cursor: pointer;
  transition: background-color 150ms ease;
}

.modal__close:hover {
  background-color: #e2d9ff;
}

.modal__close svg {
  width: 1.25vw;
  height: 1.25vw;
}

.modal__close svg path {
  fill: #af97ff;
}

#share-modal {
  width: 39.0625vw;
}

.share-content__episode {
  display: flex;
  flex-direction: row;
  gap: 1.5625vw;
  margin-bottom: 1.5625vw;
}

.share-content__img {
  width: 34.2465%;
  height: auto;
}

.share-content__img img {
  max-width: 100%;
  width: 100%;
  aspect-ratio: 1.6/1;
  object-fit: cover;
  object-position: center;
  border-radius: 0.7812vw;
}

.share-content__episode-details-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.share-content__episode-number {
  text-transform: uppercase;
  font-family: "PoppinsBlack";
  font-size: max(14px, 1.0937vw);
}

.share-content__episode-title {
  font-size: max(16px, 1.25vw);
}

.share-content__cta {
  position: relative;
}

.share-content__url {
  color: #221452;
  line-height: 1;
  padding: 1.5625vw 6.5625vw 1.5625vw 1.5625vw;
  border: solid 1px #ebe5ff;
  border-radius: 28.125vw;
  font-size: 1.25vw;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.share-content__button {
  font-family: "PoppinsBlack";
  line-height: 1;
  position: absolute;
  top: 50%;
  right: 0.5625vw;
  transform: translateY(-50%);
  text-transform: uppercase;
  color: #fff;
  font-size: 0.9375vw;
  padding: 1.25vw;
  background-color: #6b43ef;
  border: none;
  border-radius: 2.25vw;
  cursor: pointer;
  box-shadow: 0 1px 3px 0 rgba(17, 10, 41, 0.12),
    0 4px 16px 0 rgba(17, 10, 41, 0.16);
}

@media (max-width: 599px) {
  #share-modal {
    width: 335px;
    padding: 30px;
    border-radius: 24px;
  }

  #share-modal .modal__title {
    font-size: 28px;
  }

  #share-modal .modal__close {
    padding: 9px;
  }

  #share-modal .modal__close svg {
    width: 14px;
    height: 14px;
  }

  #share-modal .modal__header {
    height: 32px;
    margin-bottom: 30px;
  }

  #share-modal .share-content__img {
    width: 150px;
    height: auto;
  }

  #share-modal .share-content__img img {
    border-radius: 10px;
  }

  #share-modal .share-content__episode {
    gap: 20px;
    margin-bottom: 20px;
  }

  #share-modal .share-content__episode-details-container {
    flex-grow: 1;
    justify-content: flex-start;
  }

  #share-modal .share-content__url {
    padding: 7px 20px;
    font-size: 14px;
    height: 41px;
    display: flex;
    align-items: center;
  }

  #share-modal .share-content__button {
    padding: 18px 16px;
    font-size: 12px;
    border-radius: 34px;
    right: 7px;
  }
}

/* for grown ups modal */
.modal-for-grown-ups .modal__title {
  color: #4427a4;
  padding-top: 72px;
  font-size: 50px;
}

.modal-for-grown-ups .modal__header {
  margin-bottom: 40px;
}

.modal-for-grown-ups .modal__close {
  padding: 20px;
}

.modal-for-grown-ups .modal__close svg {
  width: 32px;
  height: 32px;
}

.modal-for-grown-ups .modal__body * {
  font-size: 20px;
}

.modal-for-grown-ups .modal__body *:first-child {
  margin-top: 0;
}

.modal-for-grown-ups .modal__body *:last-child {
  margin-bottom: 0;
}

@media (max-width: 599px) {
  .modal-for-grown-ups {
    padding: 20px;
  }

  .modal-for-grown-ups .modal__header {
    margin-bottom: 20px;
  }

  .modal-for-grown-ups .modal__title {
    font-size: 32px;
    padding-top: 54px;
  }

  .modal-for-grown-ups .modal__close {
    padding: 8px;
  }

  .modal-for-grown-ups .modal__close svg {
    width: 24px;
    height: 24px;
  }

  .modal-for-grown-ups .modal__body * {
    font-size: 14px;
  }
}

/* slide-right */
.slide-right {
  height: 100dvh;
  width: 660px;
  overflow-y: auto;
  border-radius: 0;
  left: auto;
  top: 0;
  right: 0;
  transform: translate(100%, 0);
  animation: slide-right-in 0.4s forwards;
}

@media (max-width: 1050px) {
  .slide-right {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100dvh;
    min-width: none;
    max-height: none;
    border-radius: 0;
    animation: modalFadeIn 0.4s forwards;
  }
}
